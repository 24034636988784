
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { CompanyClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class EditScoringModal extends Vue {

    @Prop() initCallback: Function;

    @Prop({
        default: () => new OM.PointsModel()
    }) pointsConfig: OM.PointsModel;
    localPointsConfig: OM.PointsModel = new OM.PointsModel();

    newPointConfig: OM.TotalPointsAndValue = new OM.TotalPointsAndValue();
    loaded: boolean = false;

    created() {
        this.localPointsConfig.category = this.pointsConfig.category;
        this.pointsConfig.totalPointsAndValues.forEach(element => {
            this.localPointsConfig.totalPointsAndValues.push({...element});
        });

        setTimeout(() => {
            this.loaded = true;
        }, 100);
    }

    addPointConfig() {
        this.localPointsConfig.totalPointsAndValues.push(this.newPointConfig);
        this.newPointConfig = new OM.TotalPointsAndValue();
    }
    removePointConfig(pointConfigIndex: number) {
        this.localPointsConfig.totalPointsAndValues.splice(pointConfigIndex, 1);
    }

    saveConfig() {
        CompanyClient.editTotalPointsAndValues(this.localPointsConfig)
        .then(x => {
            this.initCallback();
        })
    }
    
}
