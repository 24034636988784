
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { CompanyClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class EditScoringTableCertificationsModal extends Vue {

    @Prop() initCallback: Function;

    @Prop({
        default: () => new OM.TableCategories()
    }) macroCat: OM.TableCategories;
    localMacroCat: OM.TableCategories = new OM.TableCategories;

    newCertificationConfig: OM.CertificationConfiguration = new OM.CertificationConfiguration();
    loaded: boolean = false;

    created() {
        this.localMacroCat.categoryTitle = this.macroCat.categoryTitle;
        this.macroCat.certifications.forEach(cert => {
            this.localMacroCat.certifications.push(cert);
        });
        this.macroCat.subCategories.forEach(subCat => {
            this.localMacroCat.subCategories.push(subCat);
        });

        this.macroCat.subCategories.forEach(element => {
            var newSubCat = new OM.KeyValuePairOfStringAndDecimal();
            newSubCat.key = element.key;
            this.newCertificationConfig.subCategories.push(newSubCat);
        });

        setTimeout(() => {
            this.loaded = true;
        }, 100);
    }

    addCert() {
        this.newCertificationConfig.subCategories.forEach(element => {
            if(!element.value)
                element.value = 0;
        });

        this.localMacroCat.certifications.push(this.newCertificationConfig);

        this.newCertificationConfig = new OM.CertificationConfiguration();
        this.macroCat.subCategories.forEach(element => {
            var newSubCat = new OM.KeyValuePairOfStringAndDecimal();
            newSubCat.key = element.key;
            this.newCertificationConfig.subCategories.push(newSubCat);
        });
    }
    removeCert(certIndex: number) {
        this.localMacroCat.certifications.splice(certIndex, 1);
    }

    saveConfig() {
        CompanyClient.editScoringTableCertificationConfiguration(this.localMacroCat)
        .then(x => {
            this.initCallback();
        })
    }
    
}
