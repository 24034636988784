
import { Options, Vue } from 'vue-class-component';
import { CompanyClient } from '@/services/Services';
import EditScoringTableCertificationsModal from '../modals/editScoringTableCertificationsModal.vue';
import EditScoringTableCategoriesModal from '../modals/editScoringTableCategoriesModal.vue';
import EditScoringModal from '../modals/editScoringModal.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class ScoringConfiguration extends Vue {

    scoringConfig: OM.ScoringConfiguration = new OM.ScoringConfiguration();
    scoringTableConfig: OM.ScoringTableConfiguration = new OM.ScoringTableConfiguration();

    activeSection: string = "";
    loaded: boolean = false;

    created() {
        this.init();
    }

    init() {
        Promise.all([
            CompanyClient.getOrCreateScoringConfigurationForCompany(),
            CompanyClient.getOrCreateScoringTableConfigurationForCompany()
        ])
        .then(xs => {
            this.scoringConfig = xs[0];
            this.scoringTableConfig = xs[1];

            if(!this.activeSection)
                this.activeSection = this.scoringTableConfig.tableCategories[0].categoryTitle;

            this.$nextTick(() => {
                this.loaded = true;
            })
        })
    }

    get selectedConfiguration() {
        return this.scoringConfig.pointsModel.filter(x => x.category == this.activeSection)[0];
    }
    get selectedTableConfiguration() {
        return this.scoringTableConfig.tableCategories.filter(x => x.categoryTitle == this.activeSection)[0];
    }

    editScoringConfig() {
        this.$opModal.show(EditScoringModal, {
            pointsConfig: this.selectedConfiguration,
            initCallback: () => {
                this.$opModal.closeLast();
                this.init();
            }
        })
    }

    copyDefaultScoringConfig() {
        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("Attention", "Attention"),
            subtitle: this.$localizationService.getLocalizedValue("By proceeding any previous changes will be lost and the configuration will revert to the default state offered by Edmond", "By proceeding any previous changes will be lost and the configuration will revert to the default state offered by Edmond"),
            confirm: this.$localizationService.getLocalizedValue("Proceed", "Proceed"),
            confirmCb: () => {
                this.$opModal.closeLast();
                CompanyClient.returnScoringConfigurationToDefault(this.selectedConfiguration.category)
                .then(x => {
                    this.init();
                })
            },
            deny: this.$localizationService.getLocalizedValue("Avoid", "Avoid"),
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

    getTotalPoints(certification: OM.CertificationConfiguration) {
        var ris = 0;
        
        if(this.selectedTableConfiguration) {
            certification.subCategories.forEach(element => {
                var catPoints = this.selectedTableConfiguration.subCategories.filter(x => x.key == element.key)[0].value;
                ris = ris + (catPoints * element.value);
            });
        }
        
        return ris;
    }

    editScoringTableCertificationsConfig() {
        this.$opModal.show(EditScoringTableCertificationsModal, {
            macroCat: this.selectedTableConfiguration,
            initCallback: () => {
                this.$opModal.closeLast();
                this.init();
            }
        })
    }
    editScoringTableCategoriesConfig() {
        this.$opModal.show(EditScoringTableCategoriesModal, {
            macroCat: this.selectedTableConfiguration,
            initCallback: () => {
                this.$opModal.closeLast();
                this.init();
            }
        })
    }

    copyDefaultScoringTableConfig() {
        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("Attention", "Attention"),
            subtitle: this.$localizationService.getLocalizedValue("By proceeding any previous changes will be lost and the configuration will revert to the default state offered by Edmond", "By proceeding any previous changes will be lost and the configuration will revert to the default state offered by Edmond"),
            confirm: this.$localizationService.getLocalizedValue("Proceed", "Proceed"),
            confirmCb: () => {
                this.$opModal.closeLast();
                CompanyClient.returnScoringTableConfigurationToDefault(this.selectedTableConfiguration.categoryTitle)
                .then(x => {
                    this.init();
                })
            },
            deny: this.$localizationService.getLocalizedValue("Avoid", "Avoid"),
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

}
