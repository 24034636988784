
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { CompanyClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class EditScoringTableCategoriesModal extends Vue {

    @Prop() initCallback: Function;

    @Prop({
        default: () => new OM.TableCategories()
    }) macroCat: OM.TableCategories;
    localMacroCat: OM.TableCategories = new OM.TableCategories;

    newCategoryConfig: any = {};
    loaded: boolean = false;

    created() {
        this.localMacroCat.categoryTitle = this.macroCat.categoryTitle;
        this.macroCat.certifications.forEach(cert => {
            this.localMacroCat.certifications.push(cert);
        });
        this.macroCat.subCategories.forEach(subCat => {
            this.localMacroCat.subCategories.push(subCat);
        });

        setTimeout(() => {
            this.loaded = true;
        }, 100);
    }

    addCategory() {
        console.log("qui entro")
        console.log(this.newCategoryConfig)
        console.log(this.localMacroCat.subCategories)
        this.localMacroCat.subCategories.push(this.newCategoryConfig);
        console.log("dopo sono così")
        console.log(this.localMacroCat.subCategories)

        this.newCategoryConfig = {};
    }
    removeCategory(catIndex: number) {
        this.localMacroCat.subCategories.splice(catIndex, 1);
    }

    saveConfig() {
        CompanyClient.editScoringTableCategoriesConfiguration(this.localMacroCat)
        .then(x => {
            this.initCallback();
        })
    }
    
}
